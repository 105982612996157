<template>
  <div>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="hintText"
      @load="onLoad"
      class="van--list"
    >
      <div
        class="goods-list"
        v-for="(item, index) in goodsList"
        :key="item.id"
        @click="goToDetails(item.id)"
      >
        <img :src="item.used_img || defaultImg" class="goods-img" />
        <div class="goods-info">
          <div class="goods-name">
            {{ item.used_name }}
          </div>
          <div class="price">
            <div class="present-price">
              <span>￥</span>
              <span class="integer">{{ toInteger(item.price) }}</span>
              <span>{{ twoFloating(item.price) }}</span>
            </div>
            <span class="original-price">
              ￥{{ toInteger(item.original_price)
              }}{{ twoFloating(item.original_price) }}
            </span>
            <van-icon
              name="ellipsis"
              class="menu"
              size="24px"
              color="#D5D5D5"
              @click="operateBtn(item.id)"
              @click.stop
            />
          </div>
        </div>
        <!-- 编辑弹出层 -->
        <div
          class="mask-layer"
          :class="{ 'mask-layer-show': showMask === item.id }"
          @click.stop
        >
          <span class="edit-btn" @click="closeMask" @click.stop>编辑</span>
          <!-- <van-icon
            name="cross"
            class="close-icon"
            size="20"
            color="#fff"
            @click="closeMask"
            @click.stop
          /> -->
          <div class="button-box">
            <div class="button">
              <van-button
                icon="edit"
                type="default"
                round
                block
                size="small"
                @click="editGoods(item.id)"
                @click.stop
              >
                编辑商品
              </van-button>
            </div>
            <div class="button">
              <van-button
                icon="delete-o"
                type="default"
                round
                block
                size="small"
                @click="setDelGoods(item.id, index)"
                @click.stop
              >
                删除商品
              </van-button>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <div class="blank-page" v-if="isBlank">
      <div class="blank-img-box">
        <img src="~@/assets/common/no_evaluation_icon_is_available.png" />
        <span>你暂时还没有闲置商品哦！！</span>
      </div>
      <div class="button-blank">
        <van-button
          round
          block
          color="linear-gradient(to right, #FE5876,#ED301D )"
          @click="usedPublish"
        >
          发布闲置
        </van-button>
      </div>
    </div>
    <!-- <BlankPage :blank="blank" id="blank-page" v-if="isBlank"></BlankPage> -->
  </div>
</template>

<script>
// import BlankPage from "@/components/blank-page/blank-page.vue";
import { getMyUsed, setDelGoods } from "@/api/used";
import { Toast, Dialog } from "vant";
import tools from "@/common/js/tools";
export default {
  name: "used-goods-list",
  components: {
    // BlankPage,
  },
  props: {
    status: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      showMask: "",
      isBlank: false,
      hintText: "没有更多了",
      goodsList: [],
      defaultImg: require("@/assets/used/shop_logo.png"),
      page: 1,
      blank: {
        imgUrl: require("@/assets/common/no_evaluation_icon_is_available.png"),
        text: "你暂时还没有闲置商品哦！！",
      },
    };
  },
  mounted() {
    // this.getMyUsed();
  },
  methods: {
    onLoad() {
      this.getMyUsed();
    },
    editGoods(id) {
      this.$router.push({
        name: "used-publish",
        query: {
          goodsId: id,
          editStatus: "edit",
        },
      });
    },
    operateBtn(id) {
      this.showMask = id;
    },
    closeMask() {
      this.showMask = "";
    },
    goToDetails(goodsId) {
      this.$router.push({
        name: "used-details",
        query: {
          goodsId: goodsId,
          type: "my",
        },
      });
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // if (typeof num === "number") {
      //   price = String(num).split(".")[1];
      //   if (price !== undefined && price.length === 1) {
      //     price = `.${price}0`;
      //   } else {
      //     price === undefined ? (price = ".00") : (price = `.${price}`);
      //   }
      // }
      // return price;
    },
    setDelGoods(id, index) {
      // 删除商品
      let _that = this;
      Dialog.confirm({
        title: "提示",
        message: "你确认删除此商品吗？",
      })
        .then(async () => {
          const result = await setDelGoods({ ids: id });
          if (result.code === 1) {
            Toast.success("删除成功");
            _that.goodsList.splice(index, 1);
            this.getMyUsed("del");
          } else {
            Toast.fail(result.msg);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    usedPublish() {
      this.$router.push({ name: "used-publish" });
    },
    async getMyUsed(str) {
      // 获取商品信息
      const result = await getMyUsed({ status: this.status, page: this.page });
      // console.log(result);
      if (result.data.total <= 0) {
        //空白页的回调
        this.isBlank = true;
        this.$emit("showBlank", result.data.items);
        this.finished = true;
        this.hintText = "";
        return;
      }
      if (str === "del") {
        this.goodsList = result.data.items;
        return;
      }
      this.goodsList = [...this.goodsList, ...result.data.items];
      this.loading = false;
      ++this.page;
      // console.log(this.goodsList.length);
      // console.log(result.data.total);
      if (this.goodsList.length >= result.data.total) {
        this.finished = true;
        this.page = 1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.van--list {
  padding-top: 68px;
}
// #blank-page {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, 90%);
// }
.goods-list {
  position: relative;
  display: flex;
  margin: 12px 12px 0 12px;
  .goods-img {
    flex-shrink: 0;
    display: block;
    width: 129px;
    height: 129px;
    border-radius: 10px;
    margin-right: 12px;
  }
  .goods-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 16px;
    .goods-name {
      display: -webkit-box; /**对象作为伸缩盒子模型展示**/
      -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
      -webkit-line-clamp: 2; /**显示的行数**/
      overflow: hidden; /**隐藏超出的内容**/
      word-break: break-all;
    }
    .price {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-bottom: 6px;
      .present-price {
        color: #ed301d;
        .integer {
          font-size: 18px;
          font-weight: 500;
        }
      }
      .original-price {
        color: #d5d5d5;
        padding-left: 10px;
        text-decoration: line-through;
      }
    }
  }
  .menu {
    position: absolute;
    bottom: 24px;
    right: 12px;
  }
  .mask-layer {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(134, 132, 132, 0.53);
    border-radius: 10px;
    .button-box {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .edit-btn {
      font-size: 16px;
      color: #aaaaaa;
    }
    .button {
      width: 100px;
      margin-bottom: 16px;
    }
  }
  .mask-layer-show {
    display: block;
  }
  .close-icon {
    position: absolute;
    top: 14px;
    right: 20px;
  }
}
.blank-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 262px;
  left: 50%;
  transform: translate(-50%, -50%);
  .blank-img-box {
    color: #656565;
    font-size: 18px;
    text-align: center;
    width: 246px;
    margin-bottom: 26px;
    img {
      display: block;
      width: 100%;
    }
  }
  .van-button__text {
    font-size: 18px;
  }
  .van-button {
    height: 48px;
  }
}
.button-blank {
  width: 194px;
}
</style>
