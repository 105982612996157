<template>
  <div class="page">
    <van-tabs
      v-model="active"
      line-height="0"
      title-active-color="#000"
      title-inactive-color="#AAAAAA"
    >
      <van-tab
        title="已上架"
        :title-class="[{ 'text-weight': active === 0 }, 'text-title']"
        :name="1"
      >
        <div class="used-goods-list">
          <UsedGoodsList
            :status="active"
            @showBlank="showBlank"
          ></UsedGoodsList>
        </div>
      </van-tab>
      <van-tab
        title="已下架"
        :title-class="[{ 'text-weight': active === 1 }, 'text-title']"
        :name="0"
      >
        <div class="used-goods-list">
          <UsedGoodsList
            :status="active"
            @showBlank="showBlank"
          ></UsedGoodsList>
        </div>
      </van-tab>
      <template #nav-left>
        <div class="icon-left">
          <van-icon
            name="arrow-left"
            size="20"
            @click="lastStep"
            color="#9B9B9B"
          />
        </div>
      </template>
      <template #nav-right>
        <div class="button-right">
          <van-button
            round
            block
            size="small"
            color="linear-gradient(to right,#FE5876 ,#ED301D )"
            class="btn-text"
            @click="usedPublish"
          >
            发布闲置
          </van-button>
        </div>
      </template>
    </van-tabs>
  </div>
</template>

<script>
import UsedGoodsList from "../../components/used-goods-list/used-goods-list";
export default {
  name: "my-used",
  components: {
    UsedGoodsList,
  },
  data() {
    return {
      active: 1,
      isBlank: false,
    };
  },
  methods: {
    showBlank(listArr) {
      // 空白页
      this.isBlank = true;
      // if (listArr.length <= 0) {
      // }
    },
    lastStep() {
      // this.$router.push({ name: "home" });
      this.$router.go(-1);
    },
    usedPublish() {
      this.$router.push({ name: "used-publish" });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  min-height: 100vh;
  background-color: #fff;
}
/deep/.van-tabs--line .van-tabs__wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  border-bottom: 1px solid #ededed;
  height: 57px;
}
/deep/.text-title {
  font-size: 21px;
}
/deep/.text-weight {
  font-weight: 500;
}
.btn-text {
  font-size: 17px;
  height: 34px;
}
/deep/.van-tabs--line .van-tabs__wrap {
  height: 58px;
}
.icon-left {
  display: flex;
  align-items: center;
  padding-left: 12px;
}
.button-right {
  display: flex;
  align-items: center;
  width: 96px;
  padding: 0 12px;
}
.used-goods-list {
  padding-bottom: 64px;
}
</style>