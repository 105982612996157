import request from './ajax'
/**
 * 闲置模块接口
 */
const usedUrl = '/api/used/'
// 获取闲置列表
export const getUsedGoodsList = (data) => request(usedUrl + 'list', 'post', { ...data })
// 上传闲置商品
export const setPublishGoods = (data) => request(usedUrl + 'save', 'post', { ...data })
// 商品详情
export const getGoodsDetails = (data) => request(usedUrl + 'details', 'post', { ...data })
// 创建订单
export const setCreateOrder = (data) => request(usedUrl + 'create', 'post', { ...data })
// 订单支付
export const setPayOrder = (data) => request(usedUrl + 'pay-order', 'post', { ...data })
// 订单详情
export const getOrderDetails = (data) => request(usedUrl + 'order-details', 'post', { ...data })
// 我的闲置
export const getMyUsed = (data) => request(usedUrl + 'my-list', 'post', { ...data })
// 删除我的闲置
export const setDelGoods = (data) => request(usedUrl + 'del', 'post', { ...data })
// 订单列表
export const getOrderList = (data) => request(usedUrl + 'order-list', 'post', { ...data })
// 取消订单
export const cancelOrder = (data) => request(usedUrl + 'order-cancel', 'post', { ...data })
// 获取物流
export const getExpressList = (data) => request(usedUrl + 'express', 'post', { ...data })
// 订单发货
export const setOrderExpress = (data) => request(usedUrl + 'order-express', 'post', { ...data })
// 删除订单
export const setDelOrder = (data) => request(usedUrl + 'del-order', 'post', { ...data })
// 确认收货
export const setAffirmOrder = (data) => request(usedUrl + 'order-affirm', 'post', { ...data })
// 获取卖家信息
export const getShopInfo = (data) => request(usedUrl + 'shop', 'post', { ...data })
// 更新地址
export const updateAddress = (data) => request(usedUrl + 'order-address', 'post', { ...data })
// 查看物流
export const getExpressInfo = (data) => request(usedUrl + 'express-list', 'post', { ...data })
